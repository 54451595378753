import React from "react"

import "normalize.css"
import "./layout.scss"
import Header from "./header"
import Footer from "./footer"
//import "@fontsource/lato"
//import "@fontsource/noto-sans-jp"

export default function Layout({ children, location }) {
    return (
        <div>
            <Header location={location} />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    )
}
