import React from "react"
import { Link } from "gatsby"
import * as styles from "./article-card.module.scss"

export default function ArticleCard({ article }) {
    const date = article.date.split('-').join('/')
    return (
        <article className={styles.articleCard}>
            <Link to={`/article/${article.slug}/`}>
                <div className={styles.articleCardInnerContainer}>
                    <header>
                        <picture>
                            <source type="image/webp" srcSet={article.bannerImage.thumbnailWebp.url} />
                            <img
                                className={styles.articleCardBannerImage}
                                src={article.bannerImage.thumbnailJpeg.url}
                                width="800"
                                height="420"
                                alt={article.title}
                                decoding="async"
                            />
                        </picture>
                        <h2 className={styles.articleCardTitle}>{article.title}</h2>
                    </header>
                    <p className={styles.articleCardOverview}>{article.overview}</p>
                    <footer className={styles.articleCardFooter}>
                        <time dateTime={article.date} className={styles.articleCardDate} itemprop="dateModified">{date}</time>
                    </footer>
                </div>
            </Link>
        </article>
    )
}
