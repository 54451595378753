import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleCard from "../components/article-card";
import * as styles from "./article-index-by-category.module.scss";

export default function ArticleIndex({ data, location }) {
  var articleCategory = data.wagtailbase.articleCategory;
  var articles = articleCategory.allArticles;
  // sort by article date
  articles.sort(function (a, b) {
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  });

  return (
    <Layout location={location}>
      <SEO title={articleCategory.name + `の記事`} />
      <article>
        <header className={styles.articleListHeader}>
          <h1>{articleCategory.name}の記事</h1>
        </header>
        <section className={styles.articleCardsContainer}>
          {articles.map((article) => {
            return <ArticleCard article={article} key={article.slug} />;
          })}
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wagtailbase {
      articleCategory(slug: $slug) {
        name
        slug
        allArticles {
          slug
          title
          overview
          date
          bannerImage {
            url
            thumbnailWebp: rendition(fill: "800x420", format: "webp") {
              url
            }
            thumbnailJpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
        }
      }
    }
  }
`;
