import React from "react"
import { Link } from "gatsby"

import * as styles from "./footer.module.scss"

export default function Footer() {
    return (
        <footer className={styles.layoutFooter}>
            <div className={styles.followLink}>
            </div>
            <p className={styles.copy}>©︎WagtailBase. All rights reserved.</p>
        </footer>
    )
}
