// extracted by mini-css-extract-plugin
export var layoutHeader = "header-module--layout-header--SsPo9";
export var layoutHeaderShadow = "header-module--layout-header-shadow--vWa1g";
export var layoutHeaderUnpinned = "header-module--layout-header-unpinned--ZNcOj";
export var logo = "header-module--logo--y97xu";
export var logoSubTitle = "header-module--logo-sub-title--0rCOC";
export var logoSubTitleForToppage = "header-module--logo-sub-title-for-toppage--KfpmF";
export var logoSvg = "header-module--logo-svg--ESXgl";
export var menuBtn = "header-module--menu-btn--8WB1O";
export var menuBtnOpened = "header-module--menu-btn-opened--tBNdp";
export var nav = "header-module--nav--piaz0";
export var navOpen = "header-module--nav-open--6YkXI";
export var selected = "header-module--selected--95k1C";