import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import LogoIcon from "../images/svg/WagtailBase_logo.svg";
import * as styles from "./header.module.scss";

export default function Header({ location }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const header = document.getElementsByName("layoutHeader")[0];

    // ヘッダーの高さを取得
    const hH = header.clientHeight;

    // 現在地を示す変数を定義
    let pos = 0;
    let lastPos = 0;

    const onScroll = () => {
      if (pos <= 0) {
        header.classList.remove(styles.layoutHeaderShadow);
      } else {
        header.classList.add(styles.layoutHeaderShadow);
      }

      if (pos > hH && pos > lastPos) {
        header.classList.add(styles.layoutHeaderUnpinned);
      }
      // スクロール位置がヘッダーの高さ分より小さいか
      // またはスクロール位置が最後のスクロール位置より小さい場合はclass名を削除
      if (pos < hH || pos < lastPos) {
        header.classList.remove(styles.layoutHeaderUnpinned);
      }

      // 最後のスクロール位置を保存
      lastPos = pos;
      // ウィンドウの高さを取得
      const winH = window.innerHeight;

      // ページの高さを取得
      const docH = document.documentElement.scrollHeight;

      // ウィンドウが最下部達した場合のウィンドウ上部の位置を取得
      const windBtm = docH - winH;

      if (pos < hH || pos < lastPos || windBtm <= pos) {
        header.classList.remove(styles.layoutHeaderUnpinned);
      }
    };

    window.addEventListener("scroll", () => {
      // スクロールするごとにpos（現在地）の値を更新
      pos = window.scrollY;
      onScroll();
    });
  });

  const pageTypeList = location.pathname.split("/");
  const pageType = pageTypeList[pageTypeList.length - 2];
  if (pageType == "") {
    return (
      <header className={styles.layoutHeader} name="layoutHeader">
        <h1 className={styles.logo}>
          <Link to="/" title="WagtailBase">
            <LogoIcon className={styles.logoSvg} />
            <section className={styles.logoSubTitleForToppage}>
              プログラマのキャリアハックメディア
            </section>
          </Link>
        </h1>
        <div
          className={`${styles.menuBtn} ${
            menuOpen ? styles.menuBtnOpened : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
        </div>
        <nav className={`${styles.nav} ${menuOpen ? styles.navOpen : ""}`}>
          <ul>
            <li>
              <Link
                to="/article/category/programming/"
                className={
                  pageType.includes("programming") ? styles.selected : ""
                }
              >
                プログラミング
              </Link>
            </li>
            <li>
              <Link
                to="/article/category/marketing/"
                className={
                  pageType.includes("marketing") ? styles.selected : ""
                }
              >
                マーケティング
              </Link>
            </li>
            <li>
              <Link
                to="/article/category/books/"
                className={pageType.includes("books") ? styles.selected : ""}
              >
                おすすめ本
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  } else {
    return (
      <header className={styles.layoutHeader} name="layoutHeader">
        <div className={styles.logo}>
          <Link to="/" title="WagtailBase">
            <LogoIcon className={styles.logoSvg} />
            <section className={styles.logoSubTitle}>
              プログラマのキャリアハックメディア
            </section>
          </Link>
        </div>
        <div
          className={`${styles.menuBtn} ${
            menuOpen ? styles.menuBtnOpened : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
        </div>
        <nav className={`${styles.nav} ${menuOpen ? styles.navOpen : ""}`}>
          <ul>
            <li>
              <Link
                to="/article/category/programming/"
                className={
                  pageType.includes("programming") ? styles.selected : ""
                }
              >
                プログラミング
              </Link>
            </li>
            <li>
              <Link
                to="/article/category/marketing/"
                className={
                  pageType.includes("marketing") ? styles.selected : ""
                }
              >
                マーケティング
              </Link>
            </li>
            <li>
              <Link
                to="/article/category/books/"
                className={pageType.includes("books") ? styles.selected : ""}
              >
                おすすめ本
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}
